import { useEffect, useState } from 'react'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import auth from '@itau-loans-www/shopping/src/services/http/auth'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from './services'

const usePersonalData = () => {
  const [maritalOptions, setMaritalOptions] = useState()
  const [hasBirthInput, setHasBirthInput] = useState(true)
  const [occupationsOptions, setOccupationsOptions] = useState()
  const [statesOptions, setStatesOptions] = useState()
  const { product } = useProposal()
  const { changeLeadData } = useLead()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)

  const isFGTS = product?.proposal?.productCode === 'FGTS'
  const productCode = product?.proposal?.productCode

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'proposta/dados-pessoais-formalizacao',
      customLayer: {
        description: productCode
      }
    })
    changeLeadData({
      previousStep: '/proposta/ofertas'
    })
    auth({})
      .then(async () => {
        setGetLoading(true)
        services.getPersonalData().then((data) => {
          setHasBirthInput(data?.show_date_of_birth_input)
        })

        services.getMaritalStatuses().then((data) => {
          const options = data.map((item) => {
            const label = item['marital_statuses_name'].toLowerCase()

            return {
              label: label[0].toUpperCase() + label.substring(1),
              value: item['marital_statuses_id']
            }
          })
          setMaritalOptions(options)
        })

        if (isFGTS) {
          services.getOccupations().then((data) => {
            const options = data.map((item) => {
              const label = item['occupation_name'].toLowerCase()

              return {
                label: label[0].toUpperCase() + label.substring(1),
                value: item['occupation_id']
              }
            })

            setOccupationsOptions(options)
          })
        }

        http.formalization.getStates().then((data) => {
          const options = data.map((item) => {
            return {
              label: item['state_name'],
              value: item['state_id']
            }
          })

          setStatesOptions(options)
        })
      })
      .finally(() => setGetLoading(false))
  }, [isFGTS])

  const onSubmit = (value) => {
    analytics.track('buttonClick', {
      currentPage: 'proposta/dados-pessoais-formalizacao',
      detail: 'click-step-dados-pessoais-formalizacao',
      customLayer: {
        description: productCode
      }
    })
    const requestBody = {
      ...value,
      occupation_id: isFGTS ? value['occupation_id'] : 0
    }

    changeLeadData({
      personal_data: {
        ...requestBody
      }
    })

    setSubmitLoading(true)

    services
      .putPersonalData(requestBody)
      .then(({ next_step }) => {
        goTo(next_step)
      })
      .catch((error) => {
        console.log({ error })
      })
      .finally(() => setSubmitLoading(false))
  }

  return {
    maritalOptions,
    occupationsOptions,
    statesOptions,
    onSubmit,
    isFGTS,
    hasBirthInput,
    submitLoading,
    getLoading
  }
}

export default usePersonalData
